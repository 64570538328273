import { API } from 'aws-amplify';
import { getSharedPhotosForCounselor, } from '../../graphql/queries';
import { downloadMemories,deleteMemories } from '../../graphql/mutations'

export const getSharedPhotosForCounselorService = async (familyId, limit, nextToken) => {
    let { data: { getMemories }, errors } = await API.graphql({
        query: getSharedPhotosForCounselor,
        variables: { familyId, limit, nextToken }
    });
    if (errors) {
        return new Error(errors[0].message);
    }
    return getMemories
}

export const downloadPhotosService = async (bucket, memories) => {
    let { data: { downloadMemories: downloadMemoriesResponse }, errors } = await API.graphql({
        query: downloadMemories,
        variables: { bucket, memories }
    })
    if (errors) {
        return new Error(errors[0].message);
    }
    return downloadMemoriesResponse
}

export const deleteMemoriesService = async (contactId, memoryIds) => {
    let response = await API.graphql({
        query: deleteMemories,
        variables: { contactId: contactId, mediaIds: memoryIds },
    });
    return response
}